import React from 'react';
import LandingPageView2 from 'views/LandingPageView2';
import Main from 'layouts/Main';
import WithLandingPageLayout from 'WithLandingPageLayout';
import { Helmet } from 'react-helmet';

// DATA

const title = 'Best Source for Cloud Storage Pricing';
const content = {
  hero: {
    title: "Stop paying too much for",
    emphasized: "Cloud Storage",
    subtitle: "There is a better way to save on Cloud Storage from AWS, Azure, Google Cloud, and more",
    titleVariant: "h2",
    subtitleVariant: "h4",      
  },
  quoteForm: {
    quote_provider:"any",
    title: "Save 60% or more on cloud storage from leading providers",  
    subtitle: "Get the perfect match of providers from our AI and human curators",
    titleVariant: "h4",
    subtitleVariant: "h5",
    refPage: "cloud-storage-savings",   
  },
  subText: {
    title: "Cloud Storage Shopping",
    emphasized: "Simplified.",
    subtitle: "Let us take the complexity of purchasing cloud storage away from you.  We will take your requirements and source competitive quotes from leading partners and Managed Service Providers, saving you up to 60% on the cloud storage from major providers (such as AWS, Microsoft Azure and Google Cloud). You decide who to deal with and when, your information stays anonymous in the meantime.",
    subtitleEmphasized: "Request a quote today, it’s simple and free!",
    titleVariant: "h5",
    subtitleVariant: "h6",     
  }
};

// END DATA

const LandingPage = () => {
  return (
  <>
  <Helmet
    defaultTitle="WindRate | #1 Cloud Storage Pricing Engine"
    title={title}
    titleTemplate="%s | WindRate.com"
  />     
    <WithLandingPageLayout
      data={content}
      component={LandingPageView2}
      layout={Main}
    />
    </>
  )
};
 
 export default LandingPage;
 